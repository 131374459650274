@import 'src/styles/scss/mixins';

.root {
  min-height: rem(60px);
  border-width: 0 0 1px 0;
  border-bottom: 1px solid var(--divider-color);
  background-color: var(--secondary-light-color);

  color: var(--color);
  font-size: rem(14px);
  font-weight: var(--fw-bold);

  width: 100%;

  display: flex;
  align-items: center;

  @media (hover: hover) {
    cursor: pointer;
  }

  svg {
    color: var(--secondary-color);
  }

  @include greater($breakpoint-md-min) {
    justify-content: center;
  }

  &:focus-visible > svg {
    @include focus-style;
  }
}

.back-button > svg {
  left: 42px;
  @include greater($breakpoint-md-min) {
    position: absolute;
  }
}

.title-in-center {
  justify-content: center;
}

.close-button {
  position: absolute !important;
  right: 0;
  svg {
    font-weight: normal;
    color: var(--primary-color);
  }
}
