@import 'src/styles/scss/mixins';

.root {
  @include square-input;
}

.textarea {
  border-radius: 0;
  height: auto;
  min-height: rem(60px);
  padding: rem(10px) rem(15px);
}

textarea.input {
  height: auto;
  width: 100%;
  font-size: rem(14px);
  font-weight: var(--fw-regular);
  font-family: var(--ff);
  border: none;
  background: none;
  resize: vertical;

  &::placeholder {
    color: var(--primary-color);
  }
}
