.root {
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  text-align: center;
}

.opening-hours {
  text-align: center;
  text-transform: capitalize;
}
