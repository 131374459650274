@import 'src/styles/scss/mixins';

.root {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: rem(20px);

  position: absolute;
  left: 50%;
  bottom: 5%;
  transform: translate(-50%, -50%);

  height: var(--videocall-ui-button-height);

  z-index: 1000;
}

.chat {
  position: relative;
}

.notification {
  position: absolute;
  top: 18%;
  right: 12%;
  z-index: 100;
  transform: scale(1.5);
  pointer-events: none;
}
