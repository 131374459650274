@import 'src/styles/scss/mixins';

.root {
  height: 100%;
  position: relative;

  display: grid;
  grid-template-rows: calc(100% - 80px) min-content;
  justify-content: center;
}

.debug-buttons {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  z-index: 1000;
}

.end-call-modal {
  display: flex;
  flex-direction: column;

  background-color: var(--secondary-light-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: rem(20px);
  border-radius: rem(5px);
}

.end-call-modal-buttons {
  display: flex;
  flex-direction: row;
  gap: rem(10px);

  > * {
    flex-grow: 1;
    flex-basis: 0;
  }
}

.end-call-title {
  font-size: rem(16px);
  line-height: 1.2;
  margin: rem(10px) 0 rem(20px);
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
