@use 'sass:math';

@import 'src/styles/scss/mixins';

.root {
  display: flex;
  position: relative;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  --size: #{rem(24px)};

  input[type='checkbox'] {
    position: absolute;
    left: -10000px;
  }

  input[type='checkbox']:focus-visible ~ .check {
    // focus-style
    border: 2px solid var(--secondary-color);
    padding: 2px;
  }
}

.label {
  font-size: rem(14px);
  line-height: math.div(18, 14);
  font-weight: var(--fw-medium);
  display: block;
  flex-grow: 1;
}

.check {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  width: var(--size);
  height: var(--size);
  margin-right: rem(13px);

  path {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  input[type='checkbox']:checked + & path {
    visibility: visible;
    opacity: 1;
  }
}
