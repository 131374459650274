@import 'src/styles/scss/mixins';

.icon-button-root {
  background: none;
  width: inherit;

  &:hover {
    filter: brightness(1.1);
  }
}
