@import 'src/styles/scss/mixins';

.root {
  --height: #{rem(40px)};
  --color: var(--white-color);

  @include button;
  padding: 0.3em rem(12px) 0;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  min-height: var(--height);
  background-color: var(--bg-color);
  color: var(--color);
  font-size: rem(12px);
  font-weight: var(--fw-bold);
  font-family: inherit;
  line-height: 1;
  transition:
    background-color 0.3s ease-in-out,
    filter 0.1s ease-in-out;
  text-align: center;

  text-transform: uppercase;

  @media (hover: hover) {
    cursor: pointer;
  }

  &[disabled] {
    color: #bbb;
    transition-delay: 0s;
    background-color: var(--disabled-cta-color);
    cursor: not-allowed;
  }
}

.primary {
  --bg-color: var(--secondary-color);
}

.secondary {
  --bg-color: var(--primary-color);
}

.tertiary {
  --bg-color: var(--primary-softer-color);
}

.primary-softer-outlined {
  --bg-color: var(--white-color);
  color: var(--primary-softer-color);
  border: 1px solid var(--primary-softer-color);
}

.text {
  color: var(--primary-softer-color);
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    color: var(--secondary-color);
  }
}
