@import 'src/styles/scss/mixins';

.root {
  --height: #{rem(50px)};
  --control-size: #{rem(22px)};
  --border-radius: calc(var(--height) / 2);
  position: relative;
  height: var(--height);
  border-radius: var(--border-radius);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.12);
  background-color: var(--white-color);
  padding: calc((var(--height) - var(--control-size)) / 2) rem(26px);

  &:focus-within {
    @include focus-style;
  }
}

.label {
  display: block;
  font-size: rem(12px);
  font-weight: var(--fw-medium);
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: rem(9px);

  .error & {
    color: var(--secondary-color);
  }
}

.control {
  height: 100%;
}

.full-width {
  grid-column: 1 / -1;
}

.half-width {
  grid-column: auto / span 2;
}
