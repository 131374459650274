@import 'src/styles/scss/mixins';

.root {
  position: relative;
}

.display {
  font-size: rem(14px);
  font-weight: var(--fw-regular);
  margin: calc(0rem - var(--padding)) 0 calc(#{rem(3px)} - var(--padding));
  width: 100%;
  min-height: var(--height);
  display: flex;
  justify-content: center;
  align-content: flex-start;
  flex-direction: column;

  > span {
    font-weight: var(--fw-regular);
  }

  @media (hover: hover) {
    cursor: pointer;
  }
}

.nativeInput {
  position: absolute;
  left: -10000px;
}

.preview-wrapper {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(#{rem(115px)}, calc(50% - (#{rem(10px)} / 2)))
  );
  grid-gap: rem(10px);
  grid-column: 1 / -1;

  @include greater($breakpoint-md-min) {
    grid-template-columns: repeat(
      auto-fit,
      minmax(7.1875rem, calc(32% - (0.625rem / 3)))
    );
  }
}
