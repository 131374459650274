@import 'src/styles/scss/mixins';

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  flex-grow: 1;

  @include nextbutton-margin-bottom;
}

.form-fields {
  @include nextbutton-margin-bottom;

  display: flex;
  flex-direction: column;

  flex-grow: 1;

  text-align: center;

  padding: 50px 20px 0 20px;

  @include greater($breakpoint-md-min) {
    justify-content: center;
    padding: 0;
  }
}

.please-rate-text {
  margin-bottom: 0;
}

.please-rate-text,
.please-rate-scale {
  font-size: rem(12px);
}

.rating-buttons {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;

  margin-bottom: 32px;

  button {
    background-color: var(--white-color);
    color: var(--primary-color);
    height: rem(60px);
    border-radius: rem(4px);
    padding: 0;

    margin: 0 rem(2px);

    border: none;
    font-size: rem(12px);
    @media (hover: hover) {
      cursor: pointer;
    }

    &.selected {
      background-color: var(--secondary-color);
      color: var(--white-color);

      @include focus-style;
    }
  }
}

.error-text {
  margin-top: rem(16px);
  grid-column: 1 / -1;
  color: var(--secondary-color);
  text-align: center;
}
