@import 'src/styles/scss/mixins';

.root {
  position: relative;
}

.nativeInput {
  left: 0;
  width: 100%;
  bottom: 0;
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.menu {
  margin: calc(0rem - var(--padding)) 0 calc(#{rem(3px)} - var(--padding));
  width: 100%;
  min-height: var(--height);
  display: flex;
  justify-content: center;
  align-content: flex-start;
  flex-direction: column;
  font-size: rem(14px);
  font-weight: var(--fw-regular);

  > span {
    font-weight: var(--fw-regular);
    font-size: rem(16px);
    svg {
      position: relative;
      top: 3px;
    }
  }

  @media (hover: hover) {
    cursor: pointer;
  }

  &.empty > span {
    font-weight: var(--fw-regular);
  }
}

.options {
  --listbox-height-default: #{rem(300px)};

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: opacity var(--transition);
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  pointer-events: none;
  font-weight: var(--fw-regular);

  &.open {
    opacity: 1;
    background-color: var(--white-color);
    min-height: var(--height);
    max-height: var(--listbox-height, var(--listbox-height-default));
    z-index: 100;
    border-radius: rem(10px);
    box-shadow: var(--shadow);
    visibility: visible;
    pointer-events: auto;
  }

  > span {
    display: block;
    padding: 0 rem(25px);
    padding-top: var(--padding);
    font-weight: var(--fw-bold);

    > svg {
      margin-right: rem(8px);
    }
  }

  > ul {
    @include reset-list;
    max-height: calc(
      var(--listbox-height, var(--listbox-height-default)) - 5rem
    );
    overflow-y: auto;
    padding: 0;

    > li {
      padding-left: rem(25px);
      padding-right: rem(25px);

      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: calc(var(--padding) - #{rem(3px)});
        border-bottom-left-radius: rem(10px);
        border-bottom-right-radius: rem(10px);
      }
    }

    > li > button {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      margin-top: 5px;
      margin-bottom: 5px;

      font: inherit;
      cursor: pointer;

      height: rem(35px);
      display: flex;
      justify-content: stretch;
      align-items: center;
      order: 1;
      text-align: left;
      line-height: 1.1;

      &.selected {
        font-weight: var(--fw-bold);
      }

      @media (hover: hover) {
        cursor: pointer;

        &:hover {
          font-weight: var(--fw-bold);
        }
      }

      > svg {
        margin-right: rem(10px);
      }
    }
  }
}

.newCardOption {
  background: #f1f6f8;
  padding: 0 rem(-25px) calc(var(--padding) - #{rem(3px)});
  padding-top: rem(14px);
  border-top: 1px solid #b3cdda;
}

.badge {
  display: inline-block;
  padding: rem(10px) rem(8px) rem(8px) rem(8px);
  border-radius: 14px;
  font-size: rem(10px);
  font-weight: 900;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-top: rem(-6px);
  margin-left: rem(10px);
  line-height: rem(12px);
}
