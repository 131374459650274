@import 'src/styles/scss/mixins';

.root {
  border-radius: 50%;
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 12px;
  margin: 6px;
  aspect-ratio: 1;
  height: rem(48px);

  @media (hover: hover) {
    cursor: pointer;
  }

  &.error {
    background-color: var(--secondary-color);
  }
}
