@import 'src/styles/scss/mixins';

.chat-content {
  display: flex;
  flex-direction: column;

  flex-grow: 1;
  padding: 0 var(--content-padding);
  overflow-y: auto;

  .chat-messages-container {
    flex-grow: 1;
  }

  .file-upload-preview {
    flex-grow: 0;
  }
}

.chat-content-client {
  background-color: var(--secondary-light-color);
}

.input-bar {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.chat-input {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white-color);

  flex-grow: 1;
  padding: 20px 4px 20px 16px;
  width: 50%;

  textarea {
    flex-grow: 1;
    height: 100%;
    overflow-x: hidden;
  }
}

.chat-textfield {
  box-shadow: none !important;
  border-radius: 0 !important;
}

.upload {
  width: rem(40px);
  position: relative;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.send {
  flex-grow: 1;
  background-color: var(--secondary-color);
  position: relative;
  padding: 20px;

  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    color: var(--white-color);
    font-weight: var(--fw-bold);
  }
}

.preview-wrapper {
  display: flex;
  flex-direction: row;

  margin-bottom: 1em;
  overflow: auto;

  > div {
    border: 1px solid var(--light-color);

    min-width: rem(100px);
    width: rem(100px);
    margin-right: 1em;
  }
}
