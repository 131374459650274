.root {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
