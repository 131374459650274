@import 'src/styles/scss/mixins';

.root {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: rem(20px);
  background-color: var(--light-blue-color);
}

.title {
  margin-bottom: rem(10px);
}

.infos {
  display: flex;
  align-items: baseline;
  gap: rem(10px);
  margin-bottom: rem(20px);
}

.actions {
  display: flex;
  gap: rem(20px);
  justify-content: space-between;
  margin-top: rem(40px);

  > * {
    min-width: 30%;
  }
}

.time {
  font-weight: var(--fw-bold);
  font-size: rem(20px);
}

.location {
  font-size: rem(14px);
}

.error {
  margin-top: rem(20px);
  color: var(--secondary-color);
  font-size: rem(12px);
}
