@import 'src/styles/scss/mixins';

.root {
  font-size: 16px;
  font-weight: var(--fw-regular);
  font-family: var(--ff);

  &::placeholder {
    color: #acb6c3;
  }

  // remove the default styling
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  padding: 0;
}
