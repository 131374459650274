@use 'sass:math';

@import 'src/styles/scss/mixins';

.root {
  justify-content: center;

  h3 {
    margin: auto 0 rem(18px);

    @include greater($breakpoint-md-min) {
      margin: 0;
    }
  }

  .next {
    opacity: 1;
    transition: opacity 0.4s ease-in-out;

    &[disabled] {
      opacity: 0;
    }
  }
}

.cards {
  @include grid(0);
}

.card {
  --height: #{rem(136px)};
  grid-column-end: span 2;

  @include greater($breakpoint-md-min) {
    grid-column-end: span 6;
  }

  @include card;

  &.selected {
    border-color: var(--secondary-color);
  }

  &:last-child {
    margin-right: 0;
  }

  svg {
    height: auto;
    width: 100%;
    margin-bottom: rem(18px);
  }

  &.cat svg {
    margin: rem(10px) 0 rem(15px);
  }

  &.dog svg {
    margin-top: rem(20px);
  }

  @media (hover: hover) {
    cursor: pointer;
  }
}
