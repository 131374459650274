@import 'src/styles/scss/mixins';

.root {
  @include icon-button;
  @include button;
  --size: var(--height);
  background: var(--secondary-color);
  position: absolute;
  color: var(--white-color);
  width: var(--size);
  height: var(--size);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  border-top-right-radius: calc(var(--size) / 2);
  border-bottom-right-radius: calc(var(--size) / 2);

  span {
    height: 0;
  }

  svg {
    width: rem(24px);
    height: rem(24px);
  }

  @media (hover: hover) {
    cursor: pointer;
  }
}
