@import 'src/styles/scss/mixins';

.svg-root {
  width: 20%;
  padding-top: 20%;
  height: 0;
  position: relative;
  border-radius: 50%;
  background-color: #f5cab4;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 40%;
    height: 40%;
  }
}

.preview-image {
  width: 50vmin;
  height: 50vmin;
  object-fit: cover;
  border-radius: 50%;
  @include greater($breakpoint-md-min) {
    width: 20vw;
    height: 20vw;
  }
}

.pulse {
  animation: pulse-animation 2s infinite;
}

.hidden {
  visibility: hidden;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
