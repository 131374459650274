@import 'src/styles/scss/mixins';

.root {
  height: 100vh;
  padding: 0 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    font-size: rem(14px);
    font-weight: bold;
  }

  span {
    font-size: rem(13px);
    width: 40%;
  }

  a {
    color: var(--secondary-color);
  }

  @include greater($breakpoint-md-min) {
    p {
      font-size: rem(18px);
      font-weight: normal;
    }
  }
}

.participants {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 32px;

  article {
    width: 25%;
  }
}

.card {
  min-height: rem(220px);
  justify-content: flex-start;
}

.card-title-container {
  padding-left: 16px !important;
}
