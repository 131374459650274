@import 'src/styles/scss/mixins';

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;

  @include videocall-ui-padding-bottom;
}

.drawer-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

// Sets chat-Drawer height
:global(.MuiPaper-root) {
  height: 100% !important;
}
