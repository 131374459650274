@import 'src/styles/scss/mixins';

.root {
  --height: #{rem(60px)};
  --color: var(--white-color);
  --bg-color: var(--secondary-color);

  min-height: var(--height);
  outline: none;
  border: none;
  background-color: var(--bg-color);
  color: var(--color);
  font-size: rem(16px);
  font-weight: var(--fw-bold);
  transition: background-color 0.3s ease-in-out;
  transition-delay: 0.25s;

  margin-top: rem(16px);
  @media (hover: hover) {
    cursor: pointer;
  }

  &[disabled] {
    transition-delay: 0s;
    background-color: var(--disabled-cta-color);
  }
}

.fixed {
  position: fixed;
  bottom: 0;
  width: 100vw;

  @media only screen and (min-width: $breakpoint-md-min) and (min-height: $breakpoint-md-min) {
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
  }
}

.grid {
  grid-column: 4/-4;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    color: var(--secondary-color);
  }
}
