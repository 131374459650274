@use 'sass:math';

@import 'src/styles/scss/mixins';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex-grow: 1;

  @include nextbutton-margin-bottom;
  @include greater($breakpoint-md-min) {
    justify-content: center;
    align-items: center;
  }
}

.grid-container {
  @include grid;
  grid-row-gap: rem(20px);
  align-content: start;

  &:before,
  &:after {
    grid-row: 1;
    display: block;
    content: '';
  }

  &:after {
    grid-row: -1;
  }

  @include greater($breakpoint-md-min) {
    width: 60%;
  }

  @include greater($breakpoint-lg-min) {
    width: 45%;
  }
}

.control {
  // Sets the length of the select listbox
  --listbox-height: #{rem(363px)};
  grid-column: 1 / -1;
  align-items: center;
}

.file-hint {
  font-size: rem(12px);
  line-height: math.div(20, 12);
  font-weight: var(--fw-medium);
}

.error-text {
  grid-column: 1 / -1;
  color: var(--secondary-color);
  text-align: center;
}
