@use 'sass:math';

@import 'variables';
@import 'fonts';
@import 'src/styles/scss/mixins';
@import 'functions';

:root {
  --content-width: calc(100vw - #{$content-padding * 2});
  --content-padding: #{$content-padding};
  --column-gap: #{rem(5px)};
  --column-count: 4;
  --column-width: calc(
    (var(--content-width) - (var(--column-count) - 1) * var(--column-gap)) /
      var(--column-count)
  );
  --column: calc(var(--column-gap) + var(--column-width));
  --nextbutton-margin-bottom-desktop: #{$nextbutton-margin-bottom-desktop};
  --nextbutton-margin-bottom-mobile: #{$nextbutton-margin-bottom-mobile};
  --videocall-ui-button-height: #{$videocall-ui-button-height};
  --videocall-ui-padding-bottom-mobile: #{$videocall-ui-padding-bottom-mobile};
  --videocall-ui-padding-bottom-desktop: #{$videocall-ui-padding-bottom-desktop};

  --primary-color: #{$primary-color};
  --primary-soft-color: #{$primary-soft-color};
  --primary-softer-color: #{$primary-softer-color};
  --primary-beige-color: #{$primary-beige-color};
  --secondary-color: #{$secondary-color};
  --secondary-light-color: #{$secondary-light-color};
  --success-color: #{$success-color};
  --light-color: #{$light-color};
  --light-blue-color: #{$light-blue};
  --light-blue-gray-color: #{$light-blue-gray};
  --black-color: #{$black-color};
  --white-color: #{$white-color};
  --beige-color: #{$beige-color};
  --light-gray-color: #{$light-gray-color};
  --beige-strong-color: #{$beige-strong-color};
  --font-light-color: #{$font-light-color};
  --font-grey-color: #{$font-grey-color};
  --font-testimonial-color: #{$font-testimonial-color};
  --disabled-cta-color: #{$disabled-cta-color};
  --divider-color: #{$divider-color};
  --card-description-grey: #{$card-description-grey};
  --background-gradient: #{$background-gradient};

  --ff: #{$font-regular};
  --fs: #{$base-font-size};
  --fs-1: #{$font-size-1};
  --fs-1-sm: #{$font-size-1-sm};
  --fs-2: #{$font-size-2};
  --fs-3: #{$font-size-3};
  --fs-4: #{$font-size-4};
  --fs-4-sm: #{$font-size-4-sm};
  --fs-5: #{$font-size-5};
  --fw-thin: #{$font-weight-thin};
  --fw-light: #{$font-weight-light};
  --fw-regular: #{$font-weight-regular};
  --fw-medium: #{$font-weight-medium};
  --fw-bold: #{$font-weight-bold};
  --fw-bolder: #{$font-weight-bolder};
  --fw-black: #{$font-weight-black};

  --color: #{$primary-color};

  @include greater($breakpoint-md-min) {
    --column-count: 12;
    --content-padding: #{rem(41px)};
    --column-gap: #{rem(11px)};
  }
}

html {
  height: 100%;
  font-family: var(--ff);
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  &.disable-scrolling {
    max-height: 100vh;
    overflow: hidden;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: rem(18px);
  font-weight: var(--fw-regular);
  line-height: math.div(33, 18);
  letter-spacing: rem(-0.55px);
  color: var(--color);
  overflow-x: hidden;
  background-color: var(--secondary-light-color);
  height: 100%;

  &:has(.no-body-scrolling) {
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
}

img,
picture {
  line-height: 0;
}

h1,
h2 {
  font-weight: var(--fw-black);
  color: var(--primary-color);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

.sr-only {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}

:focus,
:focus-visible {
  @include focus-style;
}

:focus:not(:focus-visible) {
  outline: 0;
}
