@import 'src/styles/scss/mixins';

.root {
  position: relative;
  display: inline-flex;
  font-size: rem(12px);
  font-weight: var(--fw-bold);
  color: var(--white-color);

  input {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &:focus-visible + label {
      @include focus-style;
    }
  }

  label {
    --height: #{rem(30px)};

    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--height);
    padding: rem(2px) rem(10px) 0;
    border-radius: calc(var(--height) / 2);
    cursor: pointer;
    user-select: none;
  }
}
