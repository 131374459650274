@import 'src/styles/scss/mixins';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;

  text-align: center;

  @include videocall-ui-padding-bottom;

  @include greater($breakpoint-md-min) {
    justify-content: center;

    .image-and-card-container {
      height: 400px;
      > div {
        height: inherit;
        img,
        svg {
          height: inherit;
        }
      }
    }
  }
}

.carousel {
  > div {
    display: flex;
    flex-direction: column-reverse;
  }

  button {
    height: rem(16px);
    width: rem(16px);
    padding: 0;
    border-radius: 50%;
    background-color: var(--white-color);
    border: none;
    cursor: pointer;
    outline: inherit;
    margin: 0 rem(4px);
  }

  &.hidden {
    visibility: hidden;
  }
}

// Fixes a Firefox UX-Bug
:global(.tns-slider) {
  white-space: normal !important;
}

// Color of the currently active navigation-button
:global(.tns-nav-active) {
  background-color: var(--primary-color) !important;
}

.card {
  min-height: rem(200px);
  max-height: 40vh;
  overflow: auto;
  margin: 16px;
  align-self: stretch;
}
