@import 'src/styles/scss/mixins';

.root {
  display: flex;
  flex-direction: column;
}

.sender-container {
  display: flex;
  flex-direction: row;

  height: 64px;

  .sender-image {
    max-height: 100%;
    padding: 8px;
    border-radius: 50%;
  }

  .initials-circle {
    margin: 8px;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background-color: #60ba96;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sender-info {
    strong {
      font-size: rem(14px);
      color: var(--primary-color);
    }

    p {
      font-size: rem(11px);
      color: var(--font-grey-color);
    }
  }

  &.user-is-sender {
    justify-content: flex-end;
  }
}

.message-content {
  @include card;
  padding: 24px 16px;
  text-align: left;
  align-items: flex-start;

  display: flex;
  flex-direction: column;
  font-size: rem(12px);
  color: var(--card-description-grey);

  &.user-is-sender {
    color: var(--primary-color);
  }

  .message-text {
    white-space: pre-line;
    margin-bottom: rem(16px);
  }

  margin-bottom: 32px;
}

.attachments-container {
  $attachment-width: rem(120px);
  $attachment-height: rem(80px);

  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, $attachment-width + rem(8px));
  grid-template-rows: repeat(auto-fit, $attachment-height + rem(8px));
  position: relative;

  > * {
    object-fit: cover;
    width: $attachment-width;
    height: $attachment-height;
  }

  > div {
    border: 1px solid var(--light-color);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
