@use 'sass:math';

@import 'src/styles/scss/mixins';

.root {
  @include card;

  --padding-x: #{rem(24px)};

  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: rem(16px) rem(16px) 0 rem(16px);

  .img {
    padding: rem(8px) rem(16px);
    width: 100%;

    height: 200px;
    object-fit: cover;
  }

  .title-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: rem(16px) var(--padding-x) 0;

    img {
      border-radius: 50%;
      border: rem(2px) solid var(--primary-color);

      height: rem(50px);
      width: rem(50px);
      flex-shrink: 0;
      margin: 0 rem(16px) rem(8px) 0;
    }
  }

  .headline {
    margin: rem(8px) 0;
    padding: 0;
    width: 100%;
    text-align: left;
    color: var(--color);
    font-size: rem(14px);
    font-weight: var(--fw-bold);
  }

  .subtitle {
    width: 100%;
    padding: 0;
    text-align: start;
    color: var(--font-grey-color);
    font-size: rem(11px);
  }

  .description {
    padding: 0 var(--padding-x);
    width: 100%;
    text-align: justify;
    overflow-y: auto;
    flex-grow: 1;

    color: var(--card-description-grey);
    font-size: rem(12px);
    line-height: math.div(20, 12);
    font-weight: var(--fw-medium);
  }
}
