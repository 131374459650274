@import 'src/styles/scss/mixins';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex-grow: 1;

  @include videocall-ui-padding-bottom;

  &:after {
    grid-row: -1;
  }

  grid-column: 1/-1;
  text-align: center;

  @include greater($breakpoint-md-min) {
    justify-content: center;
  }
}

.image-and-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: 1/-1;
  margin: 32px 0 0 0;

  width: 100%;

  &.hidden {
    visibility: hidden;
  }
}

.card {
  margin: rem(16px);
  align-self: stretch;
  justify-content: flex-start;
  overflow: auto;
  max-height: 30vh;

  @include lower(600px) {
    display: none;
  }
}
