@import 'src/styles/scss/mixins';

.root {
  --default-x-padding: #{rem(20px)};

  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  align-self: center;
  justify-self: center;
  z-index: 2000;

  background-color: var(--light-blue-color);
}

.navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  height: rem(250px);
  background-image: url('/assets/booking-header.jpg');
  background-image: image-set(
    url('/assets/booking-header.jpg') type('image/jpeg'),
    url('/assets/booking-header.avif') type('image/avif')
  );
  background-size: cover;
}

.content-wrapper {
  flex-grow: 1;
}

.location {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;

  width: 80%;
}

.navigation-buttons {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  width: 100%;

  button,
  input[type='submit'],
  input[type='reset'] {
    background: none;
    border: none;
    font: inherit;
    cursor: pointer;

    background-color: #0e3152;
    color: var(--white-color);
    line-height: 1;
    font-size: rem(14px);
    padding: rem(20px) 0 rem(16px);

    &[aria-selected='true'],
    &:hover {
      background-color: #17446f;
    }
  }
}

.location-select {
  width: 100%;
}

.schedule {
  display: flex;
  flex-direction: column;
  padding: rem(8px) var(--default-x-padding);

  .day-slider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-size: rem(16px);
    padding: rem(8px) 0;

    svg {
      margin: -6px;
    }

    &__time {
      flex-grow: 1;
      text-align: center;
    }

    button {
      border: 0;
      background: none;
      appearance: none;
      cursor: pointer;

      &:disabled {
        opacity: 0.5;
        cursor: disabled;
      }
    }
  }

  .timeslot-select {
    display: grid;
    grid-gap: rem(8px);
    grid-template-columns: 1fr 1fr 1fr;

    font-size: rem(13px);
    color: var(--white-color);

    .timeslot-element {
      padding-top: 0.3em;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: rem(40px);
      border: 0;
      color: inherit;
      cursor: pointer;
      background-color: var(--beige-color);

      &:hover {
        background-color: var(--secondary-color);
      }

      &.booked {
        background-color: var(--success-color);
        cursor: inherit;
        line-height: 1.1;

        padding-left: rem(40px);
        padding-right: rem(40px);
      }
    }
  }
}

.undo {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  background: rgba(#000, 0.2);
  color: inherit;
  border: none;
  appearance: none;
  cursor: pointer;

  @include smaller(1000px) {
    font-size: rem(8px);
    writing-mode: vertical-lr;
  }
}

.next {
  font-size: rem(12px);
  font-weight: var(--fw-bold);
  overflow-y: auto;
  margin: 0 var(--default-x-padding);

  .next-element {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    color: inherit;
    font-size: rem(12px);
    letter-spacing: normal;
    font-weight: inherit;
    border: 0;
    appearance: none;
    width: 100%;
    padding: 0;
    height: rem(30px);
    cursor: pointer;

    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.101961);
    background-color: var(--white-color);

    margin-bottom: 10px;

    &.booked {
      cursor: inherit;
      background-color: var(--success-color);

      > * {
        color: var(--white-color);
      }
    }

    > div {
      padding: 4px;
    }

    .next-element-time,
    .next-element-location {
      padding: rem(3px) rem(15px) rem(2px);
      display: flex;
      align-items: center;
    }

    .next-element-time {
      width: rem(100px);
      justify-content: center;
      line-height: 1.1;
      text-align: center;
    }

    .next-element-location {
      flex-grow: 1;
      color: var(--white-color);
    }
  }
}

.location-list {
  display: flex;
  flex-wrap: wrap;
  gap: rem(5px) rem(10px);
  margin: rem(20px) var(--default-x-padding);
  padding: 0;
  list-style: none;

  > li {
    display: flex;
  }
}

.tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.panel {
  overflow: auto;
  flex-grow: 1;
  position: relative;
}

.blocker {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
}

.map {
  height: 100%;
}

.loading {
  font-size: rem(14px);
  color: var(--primary-color);
  grid-column: 1 / -1;
}

.curtain {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: rgba($color: $primary-color, $alpha: 0.8);
}

.stop-sharing {
  position: absolute;
  top: rem(20px);
  right: rem(20px);
}
