@import 'src/styles/scss/mixins';

.root {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  background-color: var(--beige-color);
  font-size: rem(13px);
  padding: rem(10px);
  z-index: 501;
}
