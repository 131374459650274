@import 'src/styles/scss/mixins';

.root {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  :global {
    video-player-container {
      width: 100%;
      aspect-ratio: 16 / 9;
      position: relative;

      canvas {
        background: white;
      }
    }

    video-player {
      width: 100%;
      aspect-ratio: 16 / 9;
    }
  }

  &.fixed {
    display: flex;
    position: fixed;

    &:not(.foreground) {
      z-index: -500;
    }

    :global {
      video-player-container {
        width: 100%;
        height: 100%;
        aspect-ratio: unset;

        video-player {
          position: absolute;
          width: auto;
          height: 100%;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

.foreground {
  z-index: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.full-screen {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  background: var(--background-gradient);

  video {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
  }
}

.client-waiting,
.in-call-client {
  transform: scale(-1, 1);
}

.client-waiting {
  video {
    opacity: 0.5;

    &.hidden {
      visibility: hidden;
    }
  }
}

.in-call-client {
  height: rem(200px);
  width: rem(128px);
  position: absolute;

  video {
    width: 100%;
  }

  //set boundaries of this draggable element
  margin: 30px 10px 80px 10px;

  @include greater($breakpoint-md-min) {
    margin: 40px 40px 80px 40px;
  }

  &-large {
    @include greater($breakpoint-md-min) {
      height: rem(300px);
      width: rem(200px);
    }
  }
}

.in-call-client-default {
  right: 5%;
  bottom: 15%;
}

.in-call-client-chat-open {
  left: 5%;
  top: 0;
}

.alone-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: rem(160px);
  height: rem(160px);
  border-radius: 50%;

  > img {
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }
}
