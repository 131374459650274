@use 'sass:math';

@import './functions';
@import './variables';

@mixin greater($min-width) {
  @media only screen and (min-width: $min-width) {
    @content;
  }
}

@mixin bigger($min-width, $min-height) {
  @media only screen and (min-width: $min-width) and (min-height: $min-height) {
    @content;
  }
}

@mixin taller($min-height) {
  @media only screen and (min-height: $min-height) {
    @content;
  }
}

@mixin smaller($max-width) {
  @media only screen and (max-width: $max-width) {
    @content;
  }
}

@mixin tinyer($max-width, $max-height) {
  @media only screen and (max-width: $max-width) and (max-height: $max-height) {
    @content;
  }
}

@mixin lower($max-height) {
  @media only screen and (max-height: $max-height) {
    @content;
  }
}

@mixin font($font-weight, $font-family: $font-regular) {
  font-family: $font-family;
  font-weight: $font-weight;
}

@mixin reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin reset-a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

@mixin font-size($font-size, $line-height) {
  font-size: rem($font-size);
  line-height: rem($line-height);
}

@mixin text-style($font-weight, $font-size, $line-height, $letter-spacing: 0) {
  @include font($font-weight);
  @include font-size($font-size, $line-height);
  letter-spacing: $letter-spacing;
}

@mixin has-webp {
  :global(.webp) & {
    @content;
  }
}

@mixin no-webp {
  :global(.no-webp) &,
  :global(.no-js) & {
    @content;
  }
}

@mixin firefox {
  :global(.ff) & {
    @content;
  }
}

@mixin no-firefox {
  :global(.no-ff) & {
    @content;
  }
}

@mixin retina {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content;
  }
}

@mixin retina-3 {
  @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 400dpi) {
    @content;
  }
}

@mixin icon-button {
  > span {
    // content is only for screen readers, thus we hide it
    text-indent: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: block;
  }
}

@mixin button {
  border: none;

  &:hover {
    filter: brightness(1.3);
  }

  &:active {
    filter: brightness(1.3) saturate(1.5);
  }
}

@mixin boxedText($backgroundColor) {
  background-color: $backgroundColor;
  font-style: normal;
  box-shadow:
    0px -0.15em 0 0.1em $backgroundColor,
    0px 0.15em 0 0.1em $backgroundColor;
  box-decoration-break: clone;
}

@mixin cardStyle() {
  border-radius: rem(13px);
  box-shadow: 0 0 3px rgba(#000, 0.12);
  background-color: var(--white-color);
  color: var(--primary-color);
}

@mixin twoGridHorizontalPadding() {
  padding-left: calc((100% / 12) + 10px);
  padding-right: calc((100% / 12) + 10px);
}

@mixin fullWidth {
  width: auto;
  margin-left: -$content-padding;
  margin-right: -$content-padding;

  @include greater($content-width + 2 * $content-padding) {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    position: relative;
    right: 50%;
    width: 100vw;
  }
}

@mixin container() {
  padding: 0 rem($content-padding);

  @include greater($breakpoint-md-min) {
    max-width: var(--content-width);
    margin: 0 auto;
  }

  @include greater($content-width + 2 * $content-padding) {
    padding-left: 0;
    padding-right: 0;
  }
}

@mixin textBlock() {
  font-size: rem(18px);
  letter-spacing: letterSpacing(-30.7);
  line-height: math.div(33, 18);
  font-weight: var(--fw-regular);

  @include greater($breakpoint-md-min) {
    font-size: rem(22px);
    line-height: rem(40px);
  }
}

@mixin grid($padding: var(--content-padding)) {
  padding: 0 $padding;
  display: grid;
  grid-gap: var(--column-gap);
  grid-template-columns: repeat(var(--column-count), 1fr);
}

@mixin desktopGrid() {
  display: grid;
  grid-gap: rem(10px);
  grid-template-columns: repeat(24, 1fr);
}

@mixin mobileGrid() {
  display: grid;
  grid-gap: rem(10px);
  grid-template-columns: repeat(5, 1fr);
}

@mixin backgroundBleed($background-color) {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: -$content-padding;
    right: -$content-padding;
    top: 0;
    bottom: 0;
    background-color: $background-color;
    z-index: -1;
  }
}

@mixin roundedButton {
  --height: #{rem(40px)};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @include button;
  background: none;
  color: var(--white-color);
  font-size: rem(14px);
  line-height: 1;
  font-weight: var(--fw-bold);
  border: 2px solid var(--white-color);
  border-radius: calc(var(--height) / 2);
  padding: 0 rem(32px);
  height: var(--height);

  @media (hover: hover) {
    cursor: pointer;
  }

  @include greater($breakpoint-md-min) {
    padding: 18px rem(50px) 16px;
    --height: #{rem(50px)};
    font-size: rem(16px);
    grid-area: button;
    align-self: center;
  }
}

@mixin rounded-input {
  --height: #{rem(50px)};
  --padding: calc((var(--height) - #{rem(20px)}) / 2);
  --shadow: 0 0 3px rgba(0, 0, 0, 0.12);
  min-height: var(--height);
  border-radius: calc(var(--height) / 2);
  box-shadow: var(--shadow);
  padding: var(--padding) rem(23px) calc(var(--padding) - #{rem(3px)});
  background-color: var(--white-color);
  display: flex;
  flex-direction: row;
}

@mixin careersTheme {
  :global(.careers-theme) & {
    @content;
  }
}

@mixin card {
  height: var(--height);
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  appearance: none;
  border-radius: rem(13px);
  color: var(--primary-color);
  outline: none;
  text-align: center;
  background-color: var(--white-color);
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 0 rem(21px);
  font-size: rem(16px);
  font-weight: var(--fw-medium);
  line-height: 1;
  margin-bottom: rem(14px);
  border: 2px solid transparent;
  --transition: 0.2s ease-in-out;
  transition:
    border-top-color var(--transition),
    border-bottom-color var(--transition),
    border-left-color var(--transition),
    border-right-color var(--transition);
}

@mixin nextbutton-margin-bottom {
  margin-bottom: var(--nextbutton-margin-bottom-mobile);
}

@mixin videocall-ui-padding-bottom {
  padding-bottom: var(--videocall-ui-padding-bottom-mobile);
  @include greater($breakpoint-md-min) {
    padding-bottom: var(--videocall-ui-padding-bottom-desktop) !important;
  }
}

@mixin filter-input {
  --height: #{rem(40px)};

  height: var(--height);
  padding: 0 calc(var(--height) / 2);
  font-size: rem(13px);
  font-weight: var(--fw-medium);
  color: var(--primary-softer-color);
  background-repeat: no-repeat;
  background-color: transparent;
  border: 1px solid #c0cad8;
  border-radius: calc(var(--height) / 2);
  appearance: none;
}

@mixin focus-style {
  outline: 2px solid var(--secondary-color);
  outline-offset: 2px;
}

@mixin square-input {
  position: relative;
  display: inline-flex;
  height: rem(40px);
  background-color: var(--white-color);
  border: 1px solid #bcc6d5;

  &.full-width {
    width: 100%;
  }

  &.error {
    border: 1px solid var(--secondary-color);
  }

  &.multiline {
    border-radius: rem(10px);
  }

  > input,
  select {
    display: block;
    outline: none;
    border: none;
    padding: rem(2px) rem(15px);
    height: 100%;
    margin: 0;
    background: none;
    line-height: 1;
    font-size: rem(14px);
    font-weight: var(--fw-regular);
    color: var(--black-color);
    flex-grow: 1;
    appearance: none;

    &:disabled {
      opacity: 0.5;
    }
  }
}

@mixin patient-plan-badge {
  display: inline-block;
  color: var(--white-color);
  background-color: var(--primary-soft-color);

  padding: rem(10px) rem(8px) rem(8px) rem(8px);
  border-radius: 14px;
  font-size: rem(10px);
  font-weight: 900;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-left: rem(10px);
  margin-top: rem(-6px);
  line-height: rem(12px);
}
