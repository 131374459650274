@import 'src/styles/scss/mixins';

.root {
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
}

.content-main {
  padding: 16px;

  @include bigger($breakpoint-md-min, 450px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: 0;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
}

.opening-hours {
  text-align: center;
  text-transform: capitalize;

  min-width: 320px;
}

.table-weekday {
  text-align: left;
  font-weight: bold;
}

.table-hours {
  text-align: right;
}

.blocked-off-until-end-of-day-title {
  font-size: 24px;
  margin-bottom: 4px;
}

.blocked-off-until-end-of-day-subtitle {
  margin-bottom: 32px;
}
