@use 'sass:math';

@import 'src/styles/scss/mixins';

.root {
  --height: #{rem(50px)};
  --border-radius: calc(var(--height) / 2);
  text-align: left;
}

.asterisk {
  display: inline;
}

.label {
  display: block;
  font-size: rem(14px);
  font-weight: var(--fw-medium);
  line-height: 1;
  margin-bottom: rem(7px);

  .error & {
    color: var(--secondary-color);
  }
}

.wrapper {
  min-height: var(--height);
  display: flex;
  position: relative;
  align-items: stretch;
  justify-self: stretch;
  flex-direction: column;
}

.helper-text {
  margin-top: rem(6px);
  font-weight: var(--fw-regular);
  font-size: rem(12px);
  line-height: math.div(24, 16);

  .error & {
    color: var(--secondary-color);
  }
}
