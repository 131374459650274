@import 'src/styles/scss/mixins';

.preview {
  height: rem(100px);
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .preview-delete {
    position: absolute;
    right: 0;
    top: 0;
    @include icon-button;
    @include button;
    background: none;
    --size: #{rem(40px)};
    width: var(--size);
    height: var(--size);

    svg {
      filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.6));
    }
  }
}
