.document-preview {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: var(--white-color);
  border: 1px solid var(--light-color);

  text-decoration: none;
  color: inherit;

  span {
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  svg {
    width: rem(38px);
    height: rem(38px);
  }
}
