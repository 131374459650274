@import 'src/styles/scss/mixins';

.root {
  @include rounded-input;

  position: relative;

  &.full-width {
    width: 100%;
  }

  &.error {
    border: 1px solid var(--secondary-color);
  }

  &.multiline {
    border-radius: rem(10px);
  }

  &.disabled {
    background-color: var(--disabled-cta-color);
    pointer-events: none;
  }

  // Puts accessability-outline into InputBase instead of this input-element.
  &:focus-within {
    box-shadow: 0 0 0 2px var(--secondary-color);

    input,
    button,
    textarea {
      outline: none;
    }
  }
}

.input {
  display: block;
  outline: none;
  border: none;
  padding: rem(2px) rem(2px) 0;
  height: rem(22px);
  margin: 0;
  background: none;
  line-height: 1;
  font-size: rem(16px);
  font-weight: var(--fw-regular);
  color: var(--primary-color);
  flex-grow: 1;
}

textarea.input {
  resize: none;
  height: auto;
  font-size: rem(16px);
  font-weight: var(--fw-regular);
  font-family: var(--ff);

  &::placeholder {
    color: var(--primary-color);
  }
}
