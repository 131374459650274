@import 'src/styles/scss/mixins';

.root {
  display: flex;
  flex-direction: column;

  flex-grow: 1;

  @include nextbutton-margin-bottom;
  @include greater($breakpoint-md-min) {
    justify-content: center;
    align-items: center;
  }
}

.grid-container {
  @include grid;
  grid-row-gap: rem(20px);
  align-content: start;

  &:before,
  &:after {
    grid-row: 1;
    display: block;
    content: '';
  }

  &:after {
    grid-row: -1;
  }

  @include greater($breakpoint-md-min) {
    width: 60%;
  }

  @include greater($breakpoint-lg-min) {
    width: 45%;
  }
}

.control {
  grid-column: 1 / -1;

  .newPetName {
    &::placeholder {
      color: var(--primary-color);
      opacity: 0.7;
    }
  }
}

.divider {
  height: 2px;
  grid-column: 1 / -1;
  background-color: var(--divider-color);
  display: block;
  border: none;
  margin: 0 calc(50% - 50vw);

  @include greater($breakpoint-md-min) {
    margin: 0;
  }
}

.selectPet > span {
  font-weight: var(--fw-bold);
}

.patientPlanBadge {
  color: var(--white-color);
  background-color: var(--primary-soft-color);
}

.patientPlanNote {
  grid-column: 1 / -1;

  display: block;
  text-align: center;

  padding: rem(10px) rem(10px) rem(6px) rem(10px);
  font-size: rem(14px);
  font-weight: var(--fw-bold);
  color: var(--white-color);
  background-color: var(--primary-soft-color);
}
