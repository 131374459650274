@import 'src/styles/scss/mixins';

.root {
  grid-column: 1/-1;
  text-align: center;

  .queue-position {
    font-size: rem(16px);
    margin-bottom: 0;
  }

  @include greater($breakpoint-md-min) {
    margin: 32px 0;
  }
}
