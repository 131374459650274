@use 'sass:math';

@import 'src/styles/scss/mixins';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex-grow: 1;

  @include nextbutton-margin-bottom;

  &.noPayment {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (min-width: $breakpoint-md-min) and (min-height: $breakpoint-md-min) {
    flex-direction: row;

    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      text-align: left;
      width: 50%;
      padding: 120px;
      font-size: rem(16px);

      strong {
        font-size: rem(60px);
      }
    }

    .form {
      justify-content: center;
      align-items: flex-start;
    }
  }
}

.grid-container {
  @include grid;
  grid-row-gap: rem(20px);
  grid-auto-flow: row dense;
  &:after {
    grid-row: -1;
  }
  grid-column: 1/-1;
  min-width: 400px;
  width: 100%;
  max-width: 650px;
  align-self: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;

  &.noPayment {
    justify-content: center;
    flex-grow: unset;
    text-align: center;
  }
}

.header {
  padding: rem(16px) rem(12px) rem(18px);
  text-align: center;
  font-size: rem(12px);
  align-items: center;
  line-height: math.div(20, 12);
  font-weight: var(--fw-medium);
  grid-column: 1 / -1;

  &.noPayment {
    justify-content: center;
    align-items: center;
    padding-bottom: rem(20px);

    > p {
      text-align: center;
    }
  }

  strong {
    display: block;
    margin-bottom: rem(14px);
    font-weight: var(--fw-bold);
    line-height: 1;
    font-size: rem(40px);
  }
}

.promoCodeHeader {
  display: flex;
  justify-content: center;
  gap: 3px;
  font-size: rem(14px);
  svg {
    position: relative;
    top: 2px;
  }
}

.full-width {
  grid-column: 1 / -1;
}

.half-width {
  grid-column: auto / span 2;
  @include greater($breakpoint-md-min) {
    grid-column: auto / span 6;
  }
}

.checkbox {
  align-items: center !important;
}

.error-text {
  grid-column: 1 / -1;
  color: var(--secondary-color);
  text-align: center;
}

.loading {
  display: flex;
  justify-content: center;
  width: 100%;
}

.next-button {
  // wide and high
  @media only screen and (min-width: $breakpoint-md-min) and (min-height: $breakpoint-md-min) {
    position: static;
    transform: translate(-50%, 50%);

    &.noPayment {
      position: relative;
      transform: unset;
      left: unset;
      margin: rem(20px) auto;
    }
  }
  // wide and low
  @media only screen and (min-width: $breakpoint-md-min) and (max-height: $breakpoint-md-min) {
    position: static;
    width: rem(300px);
    margin-top: rem(40px);
    align-self: center;
  }
}

.precall {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: var(--secondary-light-color);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.disclaimerHeader {
  font-weight: var(--fw-bolder);
  font-size: rem(16px);
  padding: 0 16vw;

  @include greater($breakpoint-md-min) {
    font-size: rem(18px);
    padding: 0;
  }
}

.disclaimer {
  font-size: rem(14px);

  @include greater($breakpoint-md-min) {
    font-size: rem(16px);
  }

  > span {
    font-weight: var(--fw-bolder);
  }
}
